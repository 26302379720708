import React from 'react';
import ReactDOM from 'react-dom';
import {withRouter, Prompt} from 'react-router';
import {Redirect, Link} from 'react-router-dom';
import Slider, {Range} from 'rc-slider';
import _ from 'lodash';
import * as d3 from 'd3';
import queryString from 'qs';
import {withConsumer} from '../app/ApplicationContext';
import {SelectStyled} from './select.style';
import {ProgressBar, Table, CondirmDialog, Btn, Switch, Hint} from '../uikit/index';
import {Check, Close} from '../uikit/icons/index';
import {recommenderTypes, algorithms} from '../utils/metadata';
import MainLayout from '../layout/index';
import {post} from '../utils/request';
import BuilderDesign from './step.design';
import Deployment from './step.deploy';
import {WidgetCreateWrapper, StepContainer, Step, PromptBody, PublishDialogWrapper, SelectInlineStyled} from './builder.style';
import NavigationPrompt from "../uikit/prompt/index";
import Brief from './brief';

export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const params = queryString.parse(window.location.search.slice(1));
    this.persistWidget = _.debounce(this._persistWidget.bind(this), 500);
    this.createMode = window.location.href.indexOf('create') >= 0;
    this.editMode = window.location.href.indexOf('edit') >= 0;
    this.state = {
      step: Number(params.step) || 1,
      touched: params.clone === 'y'
    }
  }

  render() {
    const {step = 1, data, error, loading, touched, abTestingEnabled} = this.state;
    const {widget} = data || {};
    const {site, name, otherSettingsError} = widget || {};
    const widgetId = this.props.computedMatch.params.widgetId;
    const {appState: {session}} = this.props;
    let savable = touched && !otherSettingsError;
    if (widget && widget.status !== 'Draft' && !this.validateAllStep()) {
      savable = false;
    }
    return (
      <MainLayout
        activeItem="widget"
        loading={loading}
        breadcrumb={[
          {path: '/widget', label: 'Widget'},
          {path: '#', label: this.createMode ? 'Create Widget' : `Edit Widget ${widget && widget.status === 'Draft' ? ' (Draft)' : ''}`}
          ]}>
        {this.renderPrompt()}
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/getWidgetByID`}
            params={{widgetId: widgetId, createIfNotExist: true}}
            successHandler={data => {
              this.setState({data: data});
              const {widget} = data || {};
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }

        {
          !!data && !error &&
          <WidgetCreateWrapper>
            <StepContainer clickNavigatable={this.editMode}>
              <div className="step-container" current={step}>
                <div className="left-side">
                  <ul className="steps">
                    <li className={step === 1 ? 'selected' : ''} onClick={e => this.setStep(1)}>
                      Design
                    </li>
                    <li className={step === 2 ? 'selected' : ''} onClick={e => this.setStep(2)}>
                      <span>Deployment</span>
                    </li>
                  </ul>
                  <span className="site-selector">
                    {!!site && <img className="site" src={`/img/property/${site}.png`}/>}
                    <SelectInlineStyled
                      defaultLabel={'Select Site'}
                      selected={site}
                      data={(window.sites || session.sites).concat([{key: 'others', label: 'Not specified'}])}
                      showGroupBatch={false}
                      onChange={site => this.saveWidget({...widget, site: site})}/>
                  </span>
                </div>

                <div className="basic-info">
                  <label>Widget Name: <i className="fa fa-file-text" />
                    <input type="text"
                           value={widget.name}
                           style={{width: Math.max((widget.name || '').length * 7.5, 100) + 'px'}}
                           onChange={e => this.saveWidget({...widget, name: e.target.value})} />
                  </label>
                </div>
              </div>
              <Step selected={step === 1}>
                {step === 1 && <BuilderDesign widget={widget} onChange={widget => this.saveWidget(widget)}/>}

                <div className="actions">
                  {
                    this.editMode && savable &&
                    <Btn type="reset" onClick={e => this.reset()}><i className="fa fa-undo"/> Reset</Btn>
                  }
                  <Btn
                    type="primary"
                    disabled={!this.validateStep(1)}
                    onClick={e => this.setStep(2)}>Next <i className="fa fa-angle-right" style={{margin: 'auto 0 0 5px'}}/></Btn>
                  {
                    this.editMode &&
                    <Btn type="primary"
                         disabled={!savable}
                         onClick={e => this.updateWidget()}>Save</Btn>
                  }
                </div>
              </Step>
              <Step selected={step === 2}>
                {step === 2 &&
                <Deployment
                  widget={widget}
                  abTestingEnabled={abTestingEnabled}
                  onCreateAbTesting={s => this.setState({abTestingEnabled: s})}
                  onChange={widget => this.saveWidget(widget)}/>
                }
                <div className="actions">
                  {
                    this.editMode && savable &&
                    <Btn type="reset" onClick={e => this.reset()}><i className="fa fa-undo"/> Reset</Btn>
                  }
                  <Btn type="reset" onClick={e => this.setStep(1)}><i className="fa fa-angle-left"/> Back</Btn>
                  {
                    !!this.editMode  &&
                    <Btn
                      type="primary"
                      disabled={!savable}
                      onClick={e => this.updateWidget()}>Save</Btn>
                  }
                  {
                    widget.status === 'Draft' && this.validateAllStep() &&
                    <Btn type="primary" onClick={e => this.releaseWidget()}>Publish</Btn>
                  }
                </div>
              </Step>
            </StepContainer>
          </WidgetCreateWrapper>
        }
      </MainLayout>
    )
  }

  renderPrompt() {
    const {data, touched} = this.state;
    const {widget} = data || {};
    let savable = touched;
    if(widget && widget.status !== 'Draft' && !this.validateAllStep()) {
      savable = false;
    }
    return (
      <NavigationPrompt
        key={Date.now()}
        when={(curLocation, nextLocation) => {
          return (nextLocation.pathname).indexOf('widget/create/') < 0 &&
            (nextLocation.pathname).indexOf('widget/edit/') < 0;
          ;
        }}
        disableNative={!touched}>
        {({onConfirm, onCancel}) => {
          if (!touched) {
            this.createMode && widget.status === 'Draft' && this.deleteDraft(widget, onConfirm);
            return onConfirm();
          }
          const confirmInfo = {
            type: 'form',
            backgroundClose: false,
            title: (
              <strong>
                {this.createMode && 'You are closing a widget that has not been saved.'}
                {this.editMode && 'You are closing a widget that is changed.'}
              </strong>
            ),
            width: '650px',
            cancelLabel: this.createMode ? 'Discard' : 'Discard Changes',
            okLabel: this.createMode ? 'Save as Draft' : 'Save Changes',
            ignoreLabel: 'Continue Editing',
            hideOK: this.editMode && !savable,
            onIgnore: onCancel,
            onCancel: () => {
              this.createMode && this.deleteDraft(widget, onConfirm);
              this.editMode && onConfirm();
            },
            onConfirm: () => {
              this.editMode && this.persistWidget(widget, onConfirm);
              this.createMode && onConfirm();
            },
            dialogBody: (
              <PromptBody>
                <i className="fa fa-warning"/>
                {this.createMode && 'To save the widget, click Save as Draft. The widget will be saved in your Drafts folder.'}
                {this.editMode && savable && 'To save the changes, click "Save Changes" button. '}
                {this.editMode && !savable && 'You need to finish all required settings to change the widget, or you can discard the changes.'}

              </PromptBody>
            ),
          }
          return <CondirmDialog {...confirmInfo} />;
        }}
      </NavigationPrompt>
    )
  }

  validateAllStep() {
    return this.validateStep(1) && this.validateStep(2);
  }

  validateStep(step) {
    const {widget} = this.state.data;
    if (step === 1) {
      const {name, description, site, slotGroups = [], cxenseWidget, thinkWidget, layoutConfig, tags = [], otherSettingsError} = widget || {};
      const {title, layout, css, itemStyle, layoutParams} = layoutConfig || {};
      // let isMerewardsEDM = _.some(tags, d => d.indexOf('merewards_edm') > -1);
      if (!name || !site) {
        return false;
      }
      if (site !== 'merewards' && !cxenseWidget && !thinkWidget && !slotGroups.length) {
        return false;
      }
      if (site !== 'merewards' && !cxenseWidget && !thinkWidget) {
        let hasFinishedGroup = slotGroups.some(g => {
          return !!g.name && g.resourceType && g.algorithm;
        })
        if (!hasFinishedGroup) {
          return false;
        }
        if(!_.compact(_.values(layoutParams.slotGroupMapping)).length) {
          return false;
        }
      }
      if(!layout || !itemStyle) {
        return false;
      }
      if(otherSettingsError) {
        return false;
      }
      return true;
    } else if (step === 2) {
      return true;
    }
  }

  setStep(step) {
    this.setState({step: step}, () => {
      this.props.history.push(`${window.location.pathname}?step=${step}`);
    })
  }

  reset() {
    this.setState({data: null,touched: false});
  }

  saveWidget(widget) {
    const {data} = this.state;
    this.setState({
      data: {...data, widget: widget},
      touched: true
    });
    if (!this.editMode) {
      this.persistWidget(widget);
    }
  }

  releaseWidget() {
    const {appState: {session}} = this.props;
    const {isAdmin, isOperator} = session || {};
    if(!isAdmin && !isOperator) {
      return alert(`You don't have to previledge to change widget config.`);
    }

    const {data, abTestingEnabled} = this.state;
    const {widget} = data;
    this.setState({loading: true, touched: false});
    return post('/api/releaseWidget', {widget, abTestingEnabled}).then((widget) => {
      this.setState({loading: false, data: {...data, widget: widget}}, () => {
        this.showConfirm('success', 'Widget published successfully!', (widget) => {
          this.props.history.push(`/widget`);
        });
      })
    }).catch(e => {
      this.setState({loading: false, error: e.message});
      this.showConfirm('failure', 'Failed!');
      console.error(e);
    })
  }

  updateWidget() {
    const {widget} = this.state.data;
    this.setState({touched: false});
    this.persistWidget(widget);
  }

  _persistWidget(widget, callback) {
    const {appState: {session}} = this.props;
    const {isAdmin, isOperator} = session || {};
    if(!isAdmin && !isOperator) {
      return alert(`You don't have to previledge to change widget config.`);
    }
    return post('/api/updateWidget', {widget}).then((widget) => {
      if (typeof callback === 'function') {
        callback();
      }
    }).catch(e => {
      console.error(e);
    })
  }

  deleteDraft(widget, callback) {
    widget = widget || (this.state.data || {}).widget;
    if (!widget) return;
    const {appState: {session}} = this.props;
    const {isAdmin, isOperator} = session || {};
    if(!isAdmin && !isOperator) {
      return alert(`You don't have to previledge to change widget config.`);
    }
    return post('/api/deleteWidget', {id: widget.id}).then(() => {
      if (typeof callback === 'function') {
        callback();
      }
    }).catch(e => {
      console.error(e);
    })
  }

  showConfirm(status, message, callback) {
    const confirmInfo = {
      title: (
        <PublishDialogWrapper>
          {status === 'success' && <Check className="res-success-icon"/>}
          {status === 'failure' && <Close className="res-failure-icon"/>}
        </PublishDialogWrapper>
      ),
      dialogBody: (
        <div style={{
          display: 'block',
          textAlign: 'center',
          margin: '20px 20px 10px 20px',
          lineHeight: '30px',
          fontWeight: '600'
        }}>{message}</div>
      ),
      hideCancel: true,
      onConfirm: () => {
        CondirmDialog.closeAll();
        callback && callback();
      },
      onCancel: () => {
        CondirmDialog.closeAll();
      }
    };
    ReactDOM.render(
      <CondirmDialog fullScreen={true}
                     width={'500px'}
                     {...confirmInfo} />,
      document.getElementById('global-confirm')
    );
  }
}))
